<template>
    
    <div class="h-auto w-full flex flex-col">
        <loader :loading="loading" />
        <div class="h-full overflow-auto mobile-scrollbar">
            <div class="h-auto w-full rounded-lg module p-2 flex flex-col">
                <div class="h-auto py-1">
                    <span class="text-purple text-xs font-semibold">Comms & Podcasts</span>
                </div>
                <div class="h-6 rounded-lg bg-body my-2">
                    <input type="text" placeholder="Search" v-model="searchInput" class="w-full h-6 rounded text-xs bg-body
                        flex flex-row justify-start items-center px-2">
                </div>
                <div v-if="files.length > 0" class="h-auto">
                    <div v-for="(el, index) in search(files)" :key="index" class="h-24 w-full flex-none box bg-body p-2 mb-2 flex flex-row overflow-hidden ">
                        <div :class="'rounded-lg w-1 bg-' + color(el.type) +' h-full mr-2'"></div>
                        <div v-if="el.type === 'EY Podcasts'" class="h-24 flex flex-col rounded-lg w-full overflow-hidden bg-box mb-2">
                            <div class="h-6 flex flex-row justify-between items-center px-2">
                                <span class="text-warning font-semibold text-xs">Podcasts</span>
                                <span class="text-font-gray text-xxs">
                                    {{ el.date | moment('DD/MM/YYYY')}}
                                </span>
                            </div>
                            <div class="flex-1 flex flex-col pt-2">
                                <div class="flex-1 w-full flex flex-col mt-1 justify-center items-center px-2">
                                    <span class="font-semibold text-font-gray text-xs text-center truncate-3">
                                        {{ el.title }}
                                    </span>
                                    <div v-show="isValid(el.link)">
                                        <span class="font-semibold text-font-gray text-xs pt-1 cursor-pointer" @click="goLink(el.link)">
                                        <i class="mdi mdi-open-in-new text-font-gray"></i></span>
                                    </div>
                                </div>
                                <div v-if="el.file && isAudio(el.file)" class="flex-1 w-full flex flex-row justify-center items-center">
                                    <i class="mdi mdi-stop text-sm cursor-pointer text-font-gray mr-2" @click="stop(el.id)"></i>
                                    <i class="text-xl cursor-pointer mdi text-font-gray"
                                        :class="{'mdi-pause': playmode, 'mdi-play': !playmode}" style="transition: all 1s" @click="play(el.id)"></i>
                                    <i class="mdi text-sm cursor-pointer text-font-gray ml-2"
                                        :class="{'mdi-volume-high':volumemode, 'mdi-volume-off':!volumemode}" @click="mute(el.id)"></i>
                                    <audio :id="audioId(el.id)" :src="fileRoute + el.file" controls class="hidden"></audio>
                                </div>
                                <div @click="dolike(el.id)" class="h-auto w-full mt-auto flex flex-row justify-end items-center pb-3 px-4">
                                    <span class="text-font-gray pr-1 text-xxs">
                                        {{ el.likes.length }}
                                    </span>
                                    <i class="mdi text-xxs mdi-thumb-up-outline text-font-gray" :class="melike(el.likes)"></i>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="el.type === 'EY Stories'" :key="index" class="h-24 flex w-full flex-col rounded-lg overflow-hidden bg-box mb-2">
                            <div class="h-6 flex flex-row justify-between items-center px-2">
                                <span class="text-green text-xs font-semibold">EY Stories</span>
                                <span class="text-xxs text-font-gray">{{ el.date | moment('DD/MM/YYYY')}}</span>
                            </div>
                            <div class="flex-1 flex flex-col">
                                <div class="flex-1 w-full flex flex-col mt-1 justify-center items-center px-2">
                                    <span class="font-semibold text-font-gray text-xs text-center  truncate-3">
                                        {{ el.title }}
                                    </span>
                                    <div v-show="isValid(el.link)">
                                        <span class="font-semibold text-font-gray text-xs pt-1 cursor-pointer" @click="goLink(el.link)">
                                        <i class="mdi mdi-open-in-new text-font-gray"></i></span>
                                        <span v-if="el.file && !isAudio(el.file) && el.file.length > 0" class="font-semibold text-font-gray text-xs cursor-pointer mt-1"
                                              @click="openFile(el.file, 'file')" style="color: #78849E">
                                            <i class="mdi text-font-gray mr-auto" :class="extension(el.file)"></i>
                                        </span>
                                    </div>
                                </div>
                                <div @click="dolike(el.id)" class="h-auto w-full mt-auto flex flex-row justify-end items-center pb-3 px-4">
                                    <span class="text-font-gray pr-1 text-xxs">
                                        {{ el.likes.length }}
                                    </span>
                                    <i class="mdi text-xxs mdi-thumb-up-outline text-font-gray" :class="melike(el.likes)"></i>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="el.type === 'Presidence'" :key="index" class="h-24 w-full flex flex-col bg-box rounded-lg overflow-hidden mb-2">
                            <div class="h-6 flex flex-row justify-between items-center px-2">
                                <span class="text-font-gray text-xs font-semibold">Presidencia</span>
                                <span class="text-xxs text-font-gray">{{ el.date | moment('DD/MM/YYYY')}}</span>
                            </div>
                            <div class="flex-1 w-full flex flex-col mt-1 justify-center items-center px-2">
                                    <span class="font-semibold text-font-gray text-xs text-center  truncate-3">
                                        {{ el.title }}
                                    </span>
                                    <div v-show="isValid(el.link)">
                                        <span class="font-semibold text-font-gray text-xs pt-1 cursor-pointer" @click="goLink(el.link)">
                                        <i class="mdi mdi-open-in-new text-font-gray"></i></span>
                                        <span v-if="el.file && !isAudio(el.file) && el.file.length > 0" class="font-semibold text-font-gray text-xs cursor-pointer mt-1"
                                          @click="openFile(el.file, 'file')" style="color: #78849E">
                                            <i class="mdi text-font-gray mr-auto" :class="extension(el.file)"></i>
                                        </span>
                                </div>
                            </div>
                            <div @click="dolike(el.id)" class="h-auto w-full mt-auto flex flex-row justify-end items-center pb-3 px-4">
                                    <span class="text-font-gray pr-1 text-xxs">
                                        {{ el.likes.length }}
                                    </span>
                                <i class="mdi text-xxs mdi-thumb-up-outline text-font-gray" :class="melike(el.likes)"></i>
                            </div>
                        </div>
                        <div v-else :key="index" class="h-24 w-full flex flex-col bg-box rounded-lg overflow-hidden mb-2">
                            <div class="h-6 flex flex-row justify-between items-center px-2">
                                <span class="text-font-gray text-xs font-semibold">Custom</span>
                                <span class="text-xxs text-font-gray">{{ el.date | moment('DD/MM/YYYY')}}</span>
                            </div>
                            <div class="flex-1 w-full flex flex-col mt-1 justify-center items-center px-2">
                                    <span class="font-semibold text-font-gray text-xs text-center truncate-3">
                                        {{ el.title }}
                                    </span>
                                <div v-show="isValid(el.link)">
                                        <span class="font-semibold text-font-gray text-xs pt-1 cursor-pointer" @click="goLink(el.link)">
                                        <i class="mdi mdi-open-in-new text-font-gray"></i></span>
                                        <span v-if="el.file && !isAudio(el.file) && el.file.length > 0" class="font-semibold text-font-gray text-xs cursor-pointer mt-1"
                                          @click="openFile(el.file, 'file')" style="color: #78849E">
                                            <i class="mdi text-font-gray mr-auto" :class="extension(el.file)"></i>
                                        </span>
                                </div>
                            </div>
                            <div @click="dolike(el.id)" class="h-auto w-full mt-auto flex flex-row justify-end items-center pb-3 px-4">
                                    <span class="text-font-gray pr-1 text-xxs">
                                        {{ el.likes.length }}
                                    </span>
                                <i class="mdi text-xxs mdi-thumb-up-outline text-font-gray" :class="melike(el.likes)"></i>
                            </div>
                        </div>

                    </div>
                </div>
                <div v-else-if="!loading" class="h-20 flex flex-col jusitify-center items-center">
                    <span class="text-xs text-font-gray">No hay datos</span>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import isAudio from "@/utils/IsAudio";
import ColorByOptionsCommsAndPod from "@/utils/ColorByOptionsCommsAndPod";
import {state} from '@/store';

export default {
    data() {
        return {
            searchInput: '',
            loading: false,
            files: [],
            playmode: false,
            volumemode: true
        }
    },
    methods: {
        isAudio(element) { return isAudio(element) },
        goLink(link) { this.$goLink(link) },
        openFile(file, type) { this.$openFile(file, type) },
        extension(file) {
            let result = ''
            result += this.$iconByExtension(file)
            return result
        },
        isValid(link) { return this.$is_url(link) },
        color(type) { return ColorByOptionsCommsAndPod(type) },
        play(id) {
            if (!this.playmode) {
                document.getElementById(this.audioId(id)).play()
                this.playmode = true
            }
            else {
                document.getElementById(this.audioId(id)).pause()
                this.playmode = false
            }
        },
        mute(id) {
            if (this.volumemode) {
                this.volumemode = false
                document.getElementById(this.audioId(id)).muted = true;
            }
            else {
                this.volumemode = true
                document.getElementById(this.audioId(id)).muted = false;
            }
        },
        stop(id) {
            document.getElementById(this.audioId(id)).load()
            this.playmode = false
        },
        dolike(id) {
            this.axios.post(process.env.VUE_APP_BACKEY_CMS_URL + 'like?resource=CommAndPodcast&id='+id)
                .then(response => {
                    this.load();
                })
        },
        melike(podcast) {
            return this.$meLike(podcast)
        },
        load() {
            this.loading = true
            this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=CommAndPodcast&sorts[date]=DESC&filters[active]=1', {params: state.globalParams})
                .then(response => {
                    this.loading = false
                    this.files = response.data
                })
        },
        search(data) { return this.$search(data, this.searchInput) },
        audioId(id) { return 'audio_' + id }
    },
    computed: {
        fileRoute() { return this.$fileRoute }
    },
    mounted(){
        this.load();
    }
}
</script>