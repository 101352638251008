
export default function colorsByOptionCommsAndDash(type) {
    switch (type) {
        case 'EY Stories':
            return 'green'
            break;
        case 'EY Podcasts':
            return 'yellow'
            break;
        case 'Presidence':
            return 'gray'
            break;
        default:
            return 'blue'
            break;
    }
}